
@import '~@/assets/sass/design.sass'

.batchSelectTitle
  margin-bottom: $base-spacing / 2

.permissionsList
  display: grid
  grid-gap: $base-spacing
  padding: 0
  list-style: none

.permission
  display: flex
  align-items: center

.permissionInput
  margin-right: $base-spacing

@include desktop
  .permissionsList
    grid-template-columns: repeat(3, 200px)
  .batchSelect
    width: 300px
    margin-bottom: $base-spacing
